import React, { useRef } from 'react';
import Image from 'next/image';
import Link from 'next/link';
import styled from 'styled-components';

import theme, { bp, color } from 'settings/theme';

type Props = {
  imageUrl: string,
  imageWidth: number,  // Original image width
  imageHeight: number, // Original image height
  imageAlt?: string,
  title: string,
  text?: string,
  buttonLabel?: string,
  buttonHref?: string,
  buttonTarget?: string
};

// Component
const PromoBanner: React.FC<Props> = (props) => {
  const mainVideo = useRef<HTMLVideoElement>(null);

  const adjustVideoMute = () => {
    if (mainVideo.current) {
      mainVideo.current.muted = mainVideo.current.muted ? false : true;
    }
  }
  return (
    <StyledPromoBanner className="promoBanner">

      {/* Promo Banner Hero */}
      {/* <div className="promoBanner-image">
          <Image src={props.imageUrl} width={props.imageWidth} height={props.imageHeight} alt={props.imageAlt ?? 'Promo'} />
        </div>         */}
      <video ref={mainVideo} muted onClick={adjustVideoMute} className="promoBanner-image" src={props.imageUrl} autoPlay loop style={{ width: `${props.imageWidth}`, height: `${props.imageHeight}` }}/>

      <div className="promoBanner-content">
        <div className="promoBanner-content-inner">

          <div className="promoBanner-title">
            {props.title}
          </div>

          <div className="promoBanner-text">
            {props.text}
          </div>

          {props.buttonHref &&
            <div className="promoBanner-button">
              <Link href={props.buttonHref} target={props.buttonTarget ?? ''}>
                {props.buttonLabel ?? 'GO'} <span><img src="/images/icons/icon-upright-white.svg" /></span>
              </Link>
            </div>
          }

        </div>
      </div>

    </StyledPromoBanner>
  );
};

export default PromoBanner;


const StyledPromoBanner = styled.div`

  position: relative;
  display: flex;
  flex-direction: column;

  .promoBanner-image{

    width: 100%;
    border-radius: 0.5rem;
    overflow: hidden;

    img{
      width: 100%;
      height: auto;
    }

  }

  .promoBanner-content{
    margin-top: 1.5rem;
  }

  .promoBanner-title{
    font-size: 1.5rem;
    font-weight: 700;
    text-align: center;
  }

  .promoBanner-text{
    margin-top: 0.5rem;
    font-size: 1rem;
    text-align: center;
  }

  .promoBanner-button{

    margin-top: 1.5rem;
    text-align: center;

    button, a{

      display: inline-flex;
      align-items: center;
      justify-content: center;
      gap: 0.5rem;
      padding: 0rem 1rem;
      width: 100%;
      height: 3rem;
      color: white;
      font-size: 1rem;
      font-weight: 500;
      border-radius: 0.5rem;
      background-color: ${color.green(50)};
      text-decoration: none;
      transition: all 0.15s ease-in-out;

      &:hover{
        background-color: ${color.green(60)};
      }

      span{

        width: 0.75rem;
        height: 0.75rem;

        img{
          width: 100%;
          height: auto;
        }

      }

    }

  }

  @media (min-width: ${bp(3)}) {

    flex-direction: row;
    border-radius: 1rem;
    overflow: hidden;
    background-color: ${color.gray(33.3)};

    .promoBanner-image{
      border-radius: 0;
    }

    .promoBanner-content{
      display: flex;
      align-items: center;
      justify-content: center;
      min-width: 40%;
      margin-top: 0;
      padding: 0 2rem;
    }

    .promoBanner-text{
      font-size: 0.875rem;
    }

  }

`;
