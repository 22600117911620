import React, { } from 'react';
import Image from 'next/image';
import Link from 'next/link';
import styled from 'styled-components';

import theme, {bp, color} from 'settings/theme';
import { Category } from '@prisma/client';

type Props = {
  categories: {
    title: string,
    imageUrl: string,
    href?: string // If not provided, the link will be disabled
  }[]
};

// Component
const CategoryGrid: React.FC<Props> = (props) => {

  return (
    <StyledCategoryGrid className="categoryGrid">
          
      {props.categories?.map((cat, index) => (
        <Link href={cat.href ?? ''} className="categoryGrid-item" key={index} style={{pointerEvents: cat.href ? 'auto' : 'none'}}>
          <div className="categoryGrid-item-background" style={{backgroundImage: `url(${cat.imageUrl})`}}></div>
          <div className="categoryGrid-item-label">
            {cat.title}
          </div>
        </Link>
      ))}

    </StyledCategoryGrid>
  );
};

export default CategoryGrid;


const StyledCategoryGrid = styled.div`

  position: relative;
  display: grid;
  gap: 1rem;
  grid-template-columns: 1fr 1fr;

  .categoryGrid-item{

    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    aspect-ratio: 1/1;
    border-radius: 0.5rem;

    .categoryGrid-item-background{
      z-index: -1;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border-radius: 0.5rem;
      background-size: cover;
      background-position: center;
      filter: brightness(0.75);
      overflow: hidden;
      box-shadow: inset 0 0 5rem rgba(0,0,0,0.75);
      transition: filter 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    }

    .categoryGrid-item-label{

      border-radius: 0.5rem;
      padding: 0.5rem 1rem;
      color: black;
      font-size: 0.925rem;
      font-weight: 600;
      background-color: ${color.gray(90)};
      transition: background-color 0.15s ease-in-out;

      &:hover{
        background-color: ${color.gray(100)};
      }
      
    }

    &:hover{
      .categoryGrid-item-background{
        filter: brightness(0.75);
        box-shadow: none;
      }
    }
  }

  @media (min-width: ${bp(3)}) {

    grid-template-columns: 1fr 1fr 1fr 1fr;

     .categoryGrid-item{

      border-radius: 1rem;

      .categoryGrid-item-background{
        border-radius: 1rem;
      }

    }

  }

`;
