import { ReactElement, Fragment, useState } from 'react';
import Head from 'next/head';
import Image from 'next/image';
import Link from 'next/link';
import styled from 'styled-components';
import { createServerSideHelpers } from '@trpc/react-query/server';
import { DehydratedState } from '@tanstack/react-query';
import { createContext } from 'server/context';
import { Product, Category } from '@prisma/client';

import superJson from 'utils/superJson';
import { appRouter } from 'server/routers/_app';
import getTemplateData from 'utils/getTemplateData';

import MainLayout from 'components/layout/mainLayout';
import MaxWidthWrapper from 'components/layout/maxWidthWrapper';
import PromoBanner from 'components/common/promos/promoBanner';
import CategoryGrid from 'components/common/categoryGrid/categoryGrid';
import PromoGrid from 'components/common/promos/promoGrid';

import ProductCard from 'components/products/productCard';
import SimpleButton from 'components/common/buttons/simpleButton';

import useLayout from 'providers/layout/layoutProvider';
import theme, { bp, color } from 'settings/theme';


type Props = {
  templateData: TemplateData,
  subCategories: Category[],
  productsString: string,
  trpcState: DehydratedState
};

const HomePage: NextPageExtended<Props> = (props) => {

  const layout = useLayout();

  const products: SearchResult[] = (superJson.parse(props.productsString) as any)?.results ?? [];

  return (
    <>

      <Head>
        <title>The Ormuz</title>
      </Head>

      <StyledHomePage className="homePage">

        {/* Promo */}
        <MaxWidthWrapper className="homePage-promo">
          <PromoBanner
            imageUrl="/videos/highjinx-ep1-anatomy-of-a-rug-trailer.mp4"
            imageWidth={1280}
            imageHeight={720}
            // imageUrl="/images/promotions/homepage-promo-y00ts.jpg"
            // imageWidth={1578}
            // imageHeight={983}
            imageAlt="Promo"
            title="Rugs By SCUM"
            text="The only rugs on Solana that have any value"
            buttonLabel="Buy Now"
            buttonHref="/shop/highjinx"
          />
        </MaxWidthWrapper>


        {/* Categories */}
        <MaxWidthWrapper className="homePage-categories">
          <div className="homePage-categories-title">
            SHOP BY CATEGORY
          </div>
          <div className="homePage-categories-grid">
            <CategoryGrid
              categories={[
                {
                  title: "APPAREL",
                  imageUrl: '/images/categories/category-image-apparel.jpg',
                  href: "/shop/apparel"
                },
                {
                  title: "ACCESSORIES",
                  imageUrl: '/images/categories/category-image-accessories.jpg',
                  href: "/shop/accessories"
                },
                {
                  title: "DROPS",
                  imageUrl: '/images/categories/category-image-limited-drops.jpg',
                  href: "/shop/highjinx"
                },
                {
                  title: "BRANDS",
                  imageUrl: '/images/categories/category-image-brands.jpg',
                  // href: "/"
                },
              ]}
            />
          </div>

        </MaxWidthWrapper>


        {/* Promo Grid */}
        <MaxWidthWrapper className="homePage-promoGrid">
          <PromoGrid
            items={[
              {
                imageUrl: '/images/promotions/promo-home-totes.jpg',
                title: 'TOTE BAGS',
                buttonText: 'Buy Now',
                href: '/shop/bags'
              },
              {
                imageUrl: '/images/promotions/highjinx-logomark.svg',
                title: 'HIGHJINX',
                buttonText: 'View Highjinx',
                href: '/shop/highjinx'
              }
            ]}
          />
        </MaxWidthWrapper>


        {/* Top Section  */}
        {/* <MaxWidthWrapper className="homePage-top">
          <div className="homePage-top-title">
            Shop
          </div>
          <div className="homePage-top-menu">
            <ul>
              {props.subCategories && props.subCategories.map((item) => {
                return (
                  <li key={item.category_id}>
                    <Link href={`/shop/${item.slug}`}>
                      {item.name}
                    </Link>
                  </li>
                );
              })}
            </ul>
          </div>
        </MaxWidthWrapper> */}

        {/* Hero */}
        {/* <div className="homePage-hero">
          <div className="homePage-hero-image">
            <Image src="/images/promotions/homepage-holding/homePromo.png" width={1920} height={1543} alt="The Ormuz" />
          </div>
          <div className="homePage-hero-content">
            <h1 className="homePage-hero-title">The Ormuz</h1>
            <div className="homePage-hero-subTitle">The Ultimate E-Commerce Experience</div>
            <div className="homePage-hero-cta">
              <SimpleButton as="a" href="#productTitle" fullWidth={true} buttonStyle="primary">
                SHOP NOW
              </SimpleButton>
            </div>
          </div>
        </div> */}


        {/* Products  */}
        {/* <MaxWidthWrapper className="homePage-productsTitle" id="productTitle">
          Featured products
        </MaxWidthWrapper>

        <div className="homePage-products">
          {products && products?.length > 0 ? products.map((product) => (
            <div key={product.product_id} className="homePage-product">
              <ProductCard product={product} />
            </div>
          )) : (
            <>
              {products && products.map((product) => (
                <div key={product.product_id} className="homePage-product">
                  <ProductCard product={product} />
                </div>
              ))}
            </>
          )}

        </div>
        <div className="homePage-products-bottomLine"></div> */}


        {/* <div className="holding">
          The Ormuz is coming soon
        </div> */}

        {/* Top Categories Test */}
        {/* <div>
          {props.topLevelCategories && props.topLevelCategories.map((item, index) => {
            return (
              <div key={index}>
                {item.name}
              </div>
            )
          })}
        </div> */}

        {/* Scroll test  */}
        {/* <div>
          {Array.from(Array(100).keys()).map((item, index) => {
            return (
              <div key={index}>
                Test row {index}
              </div>
            )
          })}
        </div> */}

      </StyledHomePage>

    </>
  );

}

export const getStaticProps = async ({ params }) => {

  const ssg = createServerSideHelpers({
    router: appRouter,
    ctx: await createContext(),
    transformer: superJson
  });

  const subCatsQuery = ssg.categories.get.fetch({ store_id: parseInt(process.env.NEXT_PUBLIC_STORE_ID as string), parent_category_id: null });
  const productsQuery = ssg.search.search.fetch({ is_homepage_featured: true, page_size: 36 });

  const results = await Promise.all([
    getTemplateData(),
    subCatsQuery,
    productsQuery
  ]);

  const templateData = results[0];
  const subCategories = results[1];
  const products = results[2];


  return {
    props: {
      templateData,
      subCategories,
      productsString: superJson.stringify(products),
      trpcState: ssg.dehydrate(),
    },
    revalidate: 1
  };

};

// export const getStaticPaths: GetStaticPaths<{ slug: string }> = async () => {
//   return {
//       paths: [], //indicates that no page needs be created at build time
//       fallback: 'blocking' //indicates the type of fallback
//   };
// };

HomePage.getLayout = (page: ReactElement) => {
  return (
    <MainLayout templateData={page.props.templateData}>
      {page}
    </MainLayout>
  )
}

export default HomePage;

const StyledHomePage = styled.div`

  padding-bottom: 3rem;

  .homePage-promo{
    padding: 0 1rem;
  }

  .homePage-categories{

    margin-top: 3rem;
    padding: 0 1rem;

    .homePage-categories-title{
      text-align: center;
      font-size: 1.5rem;
      font-weight: 700;
    }

    .homePage-categories-grid{
      margin-top: 1rem;
    }

  }

  .homePage-promoGrid{
    margin-top: 3rem;
    padding: 0 1rem;
  }

  /* Top Section */
  /* .homePage-top{

    padding: 2rem 1rem 1rem 1rem;

    .homePage-top-title{
      font-size: 1.25rem;
      font-weight: 550;
    }

    .homePage-top-menu{

      margin-top: 0.5rem;

      ul{

        display: flex;
        gap: 1rem;

        li{
          a{

            display: flex;
            align-items: center;
            height: 2rem;
            color: ${color.gray(30)};
            font-size: 0.85rem;
            font-weight: 550;
            opacity: 0.6;

            &:hover{
              text-decoration: underline;
            }

          }
        }

      }

    }

  } */

  /* .holding{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 75vh;
  } */

  // Hero banner
  /* .homePage-hero{

    position: relative;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    aspect-ratio: 1/1.25;
    width: 100%;
    padding-bottom: 2rem;
    border-top: 1px solid ${color.gray(66)};
    border-bottom: 1px solid ${color.gray(50)};

    .homePage-hero-image{

      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: -1;

      img{
        width: 100%;
        height: 100%;
        object-fit: cover;
      }

    }

    .homePage-hero-content{

      display: flex;
      flex-direction: column;
      align-items: center;
      max-width: calc(100% - 2rem;);
      padding: 1rem 2rem;
      border: 1px solid black;
      background-color: rgba(251, 250, 248, 75%);

      .homePage-hero-title{
        margin: 0;
        font-size: 1.5rem;
        font-weight: 500;
      }

      .homePage-hero-subTitle{
        margin-top: 0.5rem;
        color: ${color.gray(20)};
        font-size: 1rem;
        font-weight: 400;
      }

      .homePage-hero-cta{
        width: 100%;
        margin-top: 1.5rem;
      }

    }

  } */


  /* Products */
  /* .homePage-productsTitle{
    margin-top: 3rem;
    padding: 1rem 1rem 1.5rem 1rem;
    font-size: 1.25rem;
    font-weight: 550;
  }

  .homePage-products{
    display: grid;
    grid-template-columns: 1fr;
    margin: 0 1rem 3rem 1rem;
    row-gap: 2rem;
  }

  .homePage-product{
    background-color: ${color.gray(95)};
  }

  .homePage-products-bottomLine{
    display: none
  } */


  @media (min-width: ${bp(2)}) {

    /*
    .homePage-hero{
      aspect-ratio: 1/1;
    }

    .homePage-products{
      grid-template-columns: repeat( auto-fill, minmax(16rem, 1fr) );
      row-gap: 0rem;
      margin: 0;
      margin-right: -1px;
      border-top: solid 1px ${color.gray(66.6)};
    }

    .homePage-product{
      padding: 1rem;
      border-right: solid 1px ${color.gray(66.6)};
      border-bottom: solid 1px ${color.gray(66.6)};
      background-color: ${color.gray(95)};
    }

    .homePage-products-bottomLine{
      display: block;
      height: 1px;
      margin-top: -1px;
      margin-bottom: 3rem;
      border-top: solid 1px ${color.gray(66.6)};
    } */

  }

  @media (min-width: ${bp(3)}) {

    padding-bottom: 5rem;

    .homePage-promo{
      padding: 0 1.5rem;
      margin-top: 1rem;
    }

    .homePage-categories{

      padding: 0 1.5rem;

      .homePage-categories-grid{
        margin-top: 2rem;
      }

    }

    .homePage-promoGrid{
      padding: 0 1.5rem;
    }

  }

`;