import React, { } from 'react';
import Image from 'next/image';
import Link from 'next/link';
import styled from 'styled-components';

import theme, {bp, color} from 'settings/theme';
import { Category } from '@prisma/client';

type Props = {
  items: {
    imageUrl: string,
    title: string,
    buttonText: string,
    href: string
  }[]
};

// Component
const PromoGrid: React.FC<Props> = (props) => {

  return (
    <StyledPromoGrid className="promoGrid">
          
      {props.items?.map((item, index) => (
        <div className="promoGrid-item" key={index}>
          <Link href={item.href ?? '/'} className="promoGrid-item-image">
            <Image src={item.imageUrl} width={568} height={568} alt={item.title} />
          </Link>
          <div className="promoGrid-item-title">
            {item.title}
          </div>
          <div className="promoGrid-item-button">
            <Link href={item.href ?? '/'}>
              {item.buttonText  ?? 'GO'} <span><img src="/images/icons/icon-upright-white.svg" /></span>
            </Link>
          </div>
        </div>
      ))}

    </StyledPromoGrid>
  );
};

export default PromoGrid;


const StyledPromoGrid = styled.div`

  position: relative;
  display: grid;
  gap: 3rem;
  grid-template-columns: 1fr;

  .promoGrid-item{

    position: relative;
    display: block;
    aspect-ratio: 1/1;

    .promoGrid-item-image{

      width: 100%;

      img{
        width: 100%;
        height: auto;
        border-radius: 0.5rem;
        overflow: hidden;
        aspect-ratio: 1/1;
        background-color: white;
      }

    }

    .promoGrid-item-title{
      margin-top: 1.5rem;
      font-size: 1.5rem;
      font-weight: 700;
      text-align: center;
    }

    .promoGrid-item-button{

      margin-top: 1.5rem;
      text-align: center;

      button, a{

        display: inline-flex;
        align-items: center;
        justify-content: center;
        gap: 0.5rem;
        padding: 0rem 1rem;
        width: 100%;
        height: 3rem;
        color: white;
        font-size: 1rem;
        font-weight: 500;
        border-radius: 0.5rem;
        background-color: ${color.green(50)};
        text-decoration: none;
        transition: all 0.15s ease-in-out;

        &:hover{
          background-color: ${color.green(60)};
        }

        span{

          width: 0.75rem;
          height: 0.75rem;

          img{
            width: 100%;
            height: auto;
          }

        }

      }

    }

  }

  @media (min-width: ${bp(3)}) {

    gap: 1rem;
    grid-template-columns: 1fr 1fr;

    .promoGrid-item{

      .promoGrid-item-button{

        button, a{
          width: auto;
        }

      }

    }

  }

`;
